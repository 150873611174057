import { Agent } from "./../../../types/agents";
import { UserState, SetUserPayload } from "./userTypes";
import { PayloadAction } from "@reduxjs/toolkit";
import axios from "../../../requests/axios-base";
import { Student } from "types/student";

export function startLoadingUser(state: UserState, action: PayloadAction) {
  state.loading = true;
}

export async function checkToken(): Promise<null | SetUserPayload> {
  const token = localStorage.getItem("vobbToken");
  let user: null | SetUserPayload = null;

  try {
    if (!token) {
      throw new Error("No token found");
    }

    const response = await axios.post("/auth/token");
    if (response.status !== 200) {
      throw new Error("error checking token");
    }
    if (response.data?.data?.role === "user" || response.data?.data?.role === "super_agent") {
      user = {
        type: "agent",
        token: token,
        data: response.data.data,
      }
    } else if (response.data?.data?.role === "student") {
      user = {
        type: "student",
        token: token,
        data: response.data.data,
      }
    }
  } catch (err) {
    console.error(err);
  }

  return user;
}

export function setUser(
  state: UserState,
  action: PayloadAction<null | SetUserPayload>
) {
  state.loading = false;

  if (action.payload?.data) {
    state.isAuth = true;
  } else {
    state.isAuth = false;
    return;
  }

  state.token = action.payload.token;

  if (action.payload.type === "agent") {
    state.type = "agent";
    state.details.agent = action.payload.data as Agent;
  }else if (action.payload.type === "student"){
    state.type = "student";
    state.details.student = action.payload.data as Student;
  }
}

export function deleteUser(state: UserState, action: PayloadAction): UserState {
  localStorage.clear();
  const newState = {
    isAuth: false,
    token: "",
    type: null,
    details: { student: null, agent: null },
    loading: false,
  };

  return newState;
}

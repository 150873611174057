import { countries } from "country-flags-svg";

export interface optionType {
  value: string;
  label: string;
}

export const initialState: optionType = {
  label: "",
  value: "",
};

export const nationalityOptions: optionType[] = countries.map((item) => ({
  value: item.iso3,
  label: item.demonym,
}));

export const countryOptions: optionType[] = countries.map((item) => ({
  value: item.iso3,
  label: item.name,
}));

export const genderOptions: optionType[] = [
  {
    value: "male",
    label: "male",
  },
  {
    value: "female",
    label: "female",
  },
];

export const maritalOptions: optionType[] = [
  { value: "Single", label: "Single" },
  { value: "Married", label: "Married" },
  { value: "Divorced", label: "Divorced" },
];

export const educationHistoryOptions: optionType[] = [
  {
    label: "Grade 12 certificate",
    value: "ssce",
  },
  {
    label: "Bachelors degree",
    value: "bachelors",
  },
  {
    label: "Diploma",
    value: "post-grad",
  },
  {
    label: "Masters",
    value: "masters",
  },
  {
    label: "PhD",
    value: "phd",
  },
];

export const languageQualificationOptions: optionType[] = [
  {
    label: "IELTS",
    value: "ielts",
  },
  {
    label: "GRE",
    value: "gre",
  },
  {
    label: "TOEFL",
    value: "toefl",
  },
  {
    label: "OIETC",
    value: "oietc",
  },
  {
    label: "MOI",
    value: "moi",
  },
  {
    label: "Duolingo",
    value: "duolingo",
  },
];

import { initialState, optionType } from "utils/options";

export interface GeneralInfo {
  passportPhoto: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dateofBirth: string;
  gender: string;
  maritalStatus: string;
  fathersName: string;
  mothersName: string;
  nationality: optionType;
  passportNo: string;
  issueDate: string;
  expiryDate: string;
  birthPlace: string;
  country: optionType;
  address: string;
  city: string;
  state: string;
  email: string;
  phone: string;
  countryOfResidence: optionType;
}

export const initialGeneralInfo: GeneralInfo = {
  passportPhoto: "",
  firstName: "",
  middleName: "",
  lastName: "",
  dateofBirth: "",
  gender: "",
  maritalStatus: "",
  fathersName: "",
  mothersName: "",
  nationality: initialState,
  passportNo: "",
  issueDate: "",
  expiryDate: "",
  birthPlace: "",
  country: initialState,
  address: "",
  city: "",
  state: "",
  email: "",
  phone: "",
  countryOfResidence: initialState,
};

export interface EducationHistory {
  level: optionType;
  institute: string;
  program: string;
  startDate: string;
  endDate: string;
  country: optionType;
  studyLanguage: string;
  grade: string;
  maximumGrade: string;
}

export interface EducationInfo {
  eduHistory: EducationHistory[];
}

export const initialEducationHistory: EducationHistory = {
  level: initialState,
  institute: "",
  program: "",
  startDate: "",
  endDate: "",
  country: initialState,
  studyLanguage: "",
  grade: "",
  maximumGrade: "",
};

export interface Language {
  qualification: optionType;
  band: string;
  examDate: string;
  readingScore: string;
  listeningScore: string;
  writingScore: string;
  speakingScore: string;
}

export const initialLanguageData: Language = {
  qualification: initialState,
  band: "",
  examDate: "",
  readingScore: "",
  listeningScore: "",
  writingScore: "",
  speakingScore: "",
};

export interface LanguageInfo {
  languages: Language[];
}

export interface Documents {
  intlPassport: File | null;
  highSchoolTranscript: File | null;
  languageCert: File | null;
  workExperience: File | null;
  previousVisa: File | null;
  highSchoolCert: File | null;
  statementOfPurpose: File | null;
  referenceLetters: File[];
  undergradTranscript: File | null;
  undergradCert: File | null;
}

export const initialDocuments: Documents = {
  intlPassport: null,
  highSchoolTranscript: null,
  languageCert: null,
  workExperience: null,
  previousVisa: null,
  highSchoolCert: null,
  statementOfPurpose: null,
  referenceLetters: [new File([], "")],
  undergradTranscript: null,
  undergradCert: null,
};

export interface ProspectData {
  general: GeneralInfo;
  education: EducationHistory[];
  language: Language[];
  documents: Documents;
}

export interface doc {
  label: string;
  fileName: string;
  key: any;
}

export const initDoc = {
  url: "",
  key: "",
};

export interface uploadDocType {
  url: string;
  key: string;
}

export interface uploadDocsType {
  passId: uploadDocType;
  passCopy: uploadDocType;
  hst: uploadDocType;
  languageProficiency: uploadDocType;
  cv: uploadDocType;
  visa: uploadDocType;
  hsc: uploadDocType;
  sop: uploadDocType;
  reference: uploadDocType[];
  undergraduateTranscript: uploadDocType;
  undergraduateCertificate: uploadDocType;
}

export const initDocsUpload: uploadDocsType = {
  passId: initDoc,
  passCopy: initDoc,
  hst: initDoc,
  languageProficiency: initDoc,
  cv: initDoc,
  visa: initDoc,
  hsc: initDoc,
  sop: initDoc,
  reference: [],
  undergraduateTranscript: initDoc,
  undergraduateCertificate: initDoc,
};

import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import styles from "./styles.module.css";

const GetCaptcha: React.FC = () => {
  const [captcha, setCaptcha] = React.useState();
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  return (
    <div className={styles.container}>
      <ReCAPTCHA
        class="recaptcha"
        sitekey={process.env.REACT_APP_SITE_KEY}
        onChange={(token) => {
          setCaptcha(token);
          console.log(token);
        }}
        ref={recaptchaRef}
      />
      {captcha ? (
        <>
          {" "}
          <h1>Recaptcha token:</h1>
          <p>{captcha}</p>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default GetCaptcha;
